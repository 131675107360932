import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const DSWebDev = () => (
  <Layout>
    <SEO
      title="9 Reasons Data Scientists Should Learn Web Development"
      description="Here's why you should learn web development as a data scientist. Stand out from the crowd and learn a skill that will let you share your work with the world"
    />
    <h1>9 Reasons Data Scientists Should Learn Web Development</h1>
    <p>
      Data science is an amazing subject. It is commonly described as being an
      instrument that will help create so much growth that it will put us
      through the next industrial revolution. This field is so versatile that it
      can benefit pretty much every single industry if used correctly.
    </p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/O9FX6Xp.jpg"
        alt="Campbell data science learn web development as a data scientist"
        description="9 reasons a data scientist should learn web development campbell data science"
      />
    </div>

    <p>
      Photo credit: 
      <a
        href="https://unsplash.com/@photoripey"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Ibrahim Rifath
      </a>
    </p>

    <p>
      Data Scientists have a power that can help give deep insights and increase
      profits which every business can appreciate.
    </p>
    <p>
      We only have to look at a few of the largest businesses in the world right
      now. Uber, Amazon, Air bnb and many others take existing business models
      and back them heavily with data & modern techniques, see{" "}
      <a
        href="https://en.wikipedia.org/wiki/Disruptive_innovation"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        disruptive innovation
      </a>
      . All of these companies use the data to help figure out what their
      customers want and most importantly, they take action on what they learn.
    </p>
    <p>
      I’m sure that just by being on my site, you don’t need to be convinced of
      how important data science is. If you've been wondering if web development
      is good for data science, I'm sure you will find your answer here.
    </p>
    <p>
      You might be looking for an answer to web development vs data science
      because you are torn between which one to choose. Skip to the end to get
      my view.
    </p>
    <p>
      Let’s get to why data scientists should learn web development. Here's a
      little 
      <Link to="/machine-learning-web-dev/" style={{ textDecoration: "none" }}>
        machine learning web app example
      </Link>{" "}
      I made using TensorFlow that trains and runs in the browser.
    </p>
    <h2>Sharing work</h2>
    <p>
      This is the most important thing for me and the main reason I made this
      site. Data science is great for providing insights but we often present
      with static visuals, usually in a{" "}
      <a
        href="https://www.bbc.co.uk/news/technology-35038429"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        PowerPoint presentation
      </a>
      . We can try to predict all the questions we will receive after a
      presentation but how much easier would it be if the presentations were
      dynamic and interactive. What if you could build the model and let the
      user play with it and answer their own questions on the spot? Web
      development provides the tools for this.
    </p>
    <p>
      The are many javascript libraries that allow this.{" "}
      <a
        href="https://d3js.org/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        D3.js (data driven documents)
      </a>{" "}
      and{" "}
      <a
        href="https://www.chartjs.org/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        chart.js
      </a>{" "}
      are two of my favourite. Chart.js is perfect for when you want to create
      some simple charts and quickly put some visuals together. D3 is much more
      in depth. The learning curve is quite steep but once you know what you are
      doing, you can pretty much create anything you can think of. If you want
      some inspiration, there are plenty of examples on the{" "}
      <a
        href="https://github.com/d3/d3/wiki/Gallery"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        D3.js github repository
      </a>{" "}
      or you could just do a quick google search.
    </p>
    <h2>Learning another language</h2>
    <p>
      Technology changes all the time so if you become too dependant on one
      language, you risk being left behind if that language loses popularity. A
      much more important skill is to develop is a process driven mind-set.
    </p>
    <p>
      Programming languages are similar in terms of the data structures and
      things they can do. Becoming familiar with a few languages will help free
      your thought process from being bound by a language and it’s capabilities
      to actually solving the problem.
    </p>
    <p>
      Should a data scientist learn Javascript? Yes, I think so 100%. Web
      development requires HTML for the structure and CSS for the visual design.
      Javascript adds functionality and is what most web frameworks like Angular
      and{" "}
      <a
        href="https://reactjs.org/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        React
      </a>{" "}
      are written in. TensorFlow also released their popular{" "}
      <a
        href="https://www.tensorflow.org/js"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        machine learning library in Javascript
      </a>{" "}
      so you can integrate it straight into your site. There is a huge support
      network online for Javascript so it's a great time to get started.
    </p>
    <h2>It’s fun</h2>
    <p>
      If web development is your thing it can be very enjoyable. There is
      something amazing about writing some code, hitting refresh and seeing your
      vision come to life bit by bit. Even when you refresh and don’t get
      exactly what you expected, the process of searching and eventually finding
      the fix is very rewarding. It is a feeling that drives a lot of web
      developers, myself included.
    </p>
    <p>
      There are many different ways to do the same thing in web development and
      you can experiment then find what suits you best. It’s always great to
      read about the way other people do things on sites like reddit and stack
      overflow. Even speaking to people in person, you can learn about why they
      do what they do and continue learning.
    </p>
    <h2>Stand out from the crowd</h2>
    <p>
      A lot of data scientists have skills related to the field such as
      cleaning, pulling information from databases, building data pipelines,
      building all sorts of machine learning algorithms, visualisation and
      others. Not many have web development as part of their skill set.
    </p>
    <p>
      Learning web development as a data scientist will set you apart from other
      people. The field is getting{" "}
      <a
        href="https://trends.google.com/trends/explore?date=today%205-y&geo=US&q=data%20science%20jobs"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        more popular
      </a>{" "}
      as time passes meaning more competition. One funny thing about data
      science is that as libraries and techniques get better, the amount of
      human input reduces.
    </p>
    <p>
      At some point, machines will be able to take care of every skill I listed
      earlier. The demand for data scientist roles as we know them today will
      fall. You will need additional skills to stand out.
    </p>
    <h2>The community</h2>
    <p>
      The web development community is an amazing one. I have always felt as if
      the majority of people in this field are willing to give time to help
      others. They remember what it was like early on in their own careers and
      have no problem giving back. I’m sure you can relate coming from a data
      science background, I get a similar feeling when discussing problems and
      ideas with data scientists.
    </p>
    <p>
      There is support everywhere, you don’t have to search hard to find blogs,
      youtube channels, podcasts, social media pages and pretty much any other
      channel you can think of all with web development as the main topic.
    </p>
    <p>
      With regards to problem solving,{" "}
      <a
        href="https://stackoverflow.com/questions/11479064/multiple-linear-regression-in-python"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        stack overflow
      </a>{" "}
      can be your best friend. For any problem or question you have, someone
      else has probably encountered the same issue and has been given multiple
      solutions by many of the great contributors. It gives you a feeling of
      being able to leap and try anything because you have a sort of safety net
      in stack overflow. You could always{" "}
      <Link to="/outsourcing-with-fiverr/" style={{ textDecoration: "none" }}>
        outsource some of the work
      </Link>{" "}
      if you get really stuck.
    </p>
    <h2>Become independent</h2>
    <p>
      Learning how to build websites as a data scientist gives you lots of
      freedom. Lots of companies divide jobs into roles and tasks. This is how
      the majority of businesses work. Each person has a role that fits in to
      deliver a completed job to make the client happy.
    </p>
    <p>
      The freedom you gain comes from the ability to take what you know as a
      data scientist and present then even implement tools built from your
      findings. This means you can be the whole machine rather than a cog inside
      of it. You can work on projects from start to finish without having to
      rely on other people in your team. You can iterate faster and see results
      immediately.
    </p>
    <p>
      This might not be you overall aim of course. One huge benefit and it ties
      in with standing out from the crowd; you will be able to create an amazing
      online portfolio. You can present the skills you will use in a role in a
      live environment and allow potential employers to see your work. You can
      even submit your work to sites like{" "}
      <a
        href="https://www.makeovermonday.co.uk/gallery/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Makeover Monday
      </a>
      .
    </p>
    <h2>Create applications</h2>
    <p>
      I just touched on this with the ability to create an online portfolio.
      Knowledge of web development can also give you the ability to create web
      apps, you could go on to create mobile apps if you want to. Check out the
      web app I put together to demonstrate{" "}
      <Link to="/machine-learning-web-dev/" style={{ textDecoration: "none" }}>
        machine learning in web development
      </Link>
      . It uses TensorFlow js and React js.
    </p>
    <h2>Improves creativity</h2>
    <p>
      When you add more tools to your skill set, you can call from different
      areas to get inspired. Focusing on the same thing all the time can be
      draining so being able to stretch your legs and play with web development
      can give you ideas for something you might have been stuck on. I have had
      plenty of ideas for new data science projects to work on while working on
      websites.
    </p>
    <h2>It’s easy</h2>
    <p>
      Depending on how you look at it, web development in general is much easier
      than data science. Having studied both, I personally believe web
      development is easier. Once you get the basics of web design, you can
      build some amazing sites just by putting the pieces together and using
      some imagination. Sometimes you don’t need much imagination because you
      can find a few sites that you like as inspiration and copy pieces that you
      like.
    </p>
    <p>
      Data science requires a deeper sense of understanding, especially if you
      want to create actionable insights that will actually make a difference.
      You don’t have to understand how every machine learning algorithm works
      but you do need to know why you should choose one classification algorithm
      over another to get the best results.
    </p>
    <h2>Feeling Inspired?</h2>
    <p>
      Hopefully, I've managed to inspire you to take action and get started. If
      you need help or want more information on getting started just get in
      touch. I'm excited for you and hope you add one of my favourite skills to
      your arsenal.
    </p>
    <h2>Data Science vs Web Development</h2>
    <p>
      Which one should you choose, Web development or data science? I don't
      think the answer is as simple as one or the other. Both are exciting and
      should lead to a fulfilling life if you stick with them through the
      frustrating times. My view is this; why pick one when you can have both?
    </p>
    <p>
      In my view data science is far more academically challenging so if you are
      asking the question because you want to pick a subject for formal
      education, go with data science. Statistics and the tools needed for data
      science & machine learning are easier learned in a supportive group
      environment. I also believe it's easy to get a job related to data science
      with a degree.
    </p>
    <p>
      Web development is much easier to learn in isolation. There are also many
      online paid courses and youtube videos. When you are confident enough, you
      can post your work to online groups on Facebook and Reddit for review.
      Just be careful of angry people so take criticism with a pinch of salt.
    </p>
    <p>
      I personally have a degree in Construction and learned both data science
      and web development though self study. I used online resources and had
      help from mentors in each field. Good luck guys, let me know if this
      helped you. Feedback gives me the motivation to write more.
    </p>

    <h3>Archived Comments Section**</h3>
    <p>The site has been moved to a new platform and we made the choice to not include a comment section in this version. If you want to get in touch, you can reach me through the about page. There are a few reasons, the main being spam. We got so many spam comments and it took time to go through them to find the genuine comments.</p>
    <p>The previous comments held some value that was not in the article so I have archived them below. Times and dates are correct for London.</p>

    <hr />

    <p style={{marginBottom: "0px"}}><strong>Tanya Dixit</strong></p>
    <p style={{fontSize: "0.8rem", color: "grey"}}><i>March 29, 2020 at 3:23 pm</i></p>
    <p>Hi Eric,</p>
    <p>Great article!!</p>
    <p>I am a data scientist planning to refresh Javascript and then learn React and Redux. I want to be able to make websites that use ML and users can interact with and that’s why I am learning this. Do you think I am going in the right direction?</p>
    <p>Thanks</p>
    <br />
    <p style={{marginBottom: "0px"}}><strong>Eric Campbell</strong></p>
    <p style={{fontSize: "0.8rem", color: "grey"}}><i>March 30, 2020 at 5:12 pm </i></p>
    <p>Hi Tanya, Thank you.</p>
    <p>Yes, sounds like a good plan to me. That’s the same reason I got into web development. I haven’t tried Redux yet but definitely will soon, it looks amazing for state management. I learned React.js recently though and love it. I miss all the Python libraries sometimes but Tensorflow.js lets us build models and use them in the browser so easily.</p>
    <p>Thanks again, Eric</p>

    <hr />

    <p style={{marginBottom: "0px"}}><strong>Ritik Mody</strong></p>
    <p style={{fontSize: "0.8rem", color: "grey"}}><i>May 8, 2020 at 7:47 am </i></p>
    <p>I aspire to become a data scientist,but am currently learning the MERN stack. I always an inner conflict that I am straying away from my path.</p>
    <p>Thank you to this blog, I was able to clear my mind.</p>
    <p>Would you please suggest some good courses for data science?</p>
    <br />
    <p style={{marginBottom: "0px"}}><strong>Eric Campbell</strong></p>
    <p style={{fontSize: "0.8rem", color: "grey"}}><i>May 8, 2020 at 8:15 am</i></p>
    <p>It’s easy to stray because there are so many distractions. One of the best skills to have is the ability to focus and i’m glad I could help.</p>
    <p>Yes, Kirill Eremenko has 2 great courses on Udemy; Python A-Z and Machine Learning A-Z. He also has a podcast where he gives advice and interviews people in data science, if you’re interested.</p>
    <br />
    <p style={{marginBottom: "0px"}}><strong>Ritik Mody</strong></p>
    <p style={{fontSize: "0.8rem", color: "grey"}}><i>May 26, 2020 at 5:25 am</i></p>
    <p>Okay,Thank you.</p>

    <hr />

    <p style={{marginBottom: "0px"}}><strong>Irving Quevedo</strong></p>
    <p style={{fontSize: "0.8rem", color: "grey"}}><i>May 22, 2020 at 4:58 am</i></p>
    <p>Thank you that was super helpful….</p>
    <br />
    <p style={{marginBottom: "0px"}}><strong>Eric Campbell</strong></p>
    <p style={{fontSize: "0.8rem", color: "grey"}}><i>May 22, 2020 at 10:53 am</i></p>
    <p>Thank you too! I’m glad I could help</p>

    <hr />

    <p style={{marginBottom: "0px"}}><strong>Prinkesh</strong></p>
    <p style={{fontSize: "0.8rem", color: "grey"}}><i>June 11, 2020 at 5:57 pm</i></p>
    <p>Hi Eric,</p>
    <p>Your blog was very interesting and described all the potentials queries I was having in my mind.
I also belong to the field of construction. I work in the construction industry and I want to switch my industry. Can you suggest me a possible pathway to have a smooth transition, since I cannot quit my current job and considering an amount of 1-2 hrs daily that I can invest to learn coding. I get you point to learn both the web development and data science to have an extra edge. However, can I (who do not have any coding experience) can learn both or what should I start first?</p>
    <p>Thanks in Advance.</p>
    <br />
    <p style={{marginBottom: "0px"}}><strong>Eric Campbell</strong></p>
    <p style={{fontSize: "0.8rem", color: "grey"}}><i>June 11, 2020 at 6:44 pm</i></p>
    <p>Hi Prinkesh</p>
    <p>I’m glad I could help. 1-2 hours a day works, just be consistent and put in more time on your days off if you can.</p>
    <p>I would suggest choosing the one that stands out to you most because it will get hard and it will be much easier to push through on something you enjoy. For me, that was data science.</p>
    <p>There is a free pdf book called Think Python 2e, google “think python 2” and it should be the top result (greenteapress). I would recommend this to everyone that is new to coding. It takes you through most of the data structures and will give you a good idea of programming & basic algorithms. I was so glad I found this early on. You don’t even need to finish it to learn what you need.</p>
    <p>This will give you a head start for Data Science and Web Development. When you are comfortable with the concepts in that book, you will have the confidence to move onto any technology you want to.</p>
    <p>If you are happy to pay for an online course, here’s three I recommend on Udemy (If there isn’t a sale, keep checking the site until there is one);</p>
    <p>Python A-Z – by Kirill Eremenko</p>
    <p>Machine Learning A-Z – by Kirill Eremenko</p>
    <p>The Complete 2020 Web Development Bootcamp – by Angela Yee</p>
    <p>After this you can learn everything else you need from Google and YouTube for free. I hope this helps.</p>
    <p>Also, don’t worry about forgetting concepts as you learn. Just take notes as you go and you can refer back to them later. The important thing is knowing how to find the answers. Developers use google all the time.</p>

    <hr />






    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default DSWebDev
